import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.png';

import { useNavigate } from 'react-router-dom';

import useAxiosPrivate from '../hooks/useAxiosPrivate';

const UserProfile = () => {
  const { 
    loggedIn, 
    signOutUser, 
    userEmail, 
    userName, 
    userRole,
    currentColor, 
    setIsClicked, initialState
  } = useStateContext();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();


  const getUserRoleValue = () => {
    switch (userRole) {
      case 0: return "Unauthorized"
      case 1: return "Administrator"
    };
  }

  const signOut = () => {
    signOutUser();
    setIsClicked(initialState);
    navigate("/login");
  }

  useEffect(() => {
    if (!loggedIn){
        navigate('/login');
    }
  }, []);

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {userName} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">  {getUserRoleValue()}   </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> { userEmail } </p>
        </div>
      </div>
      <div className="mt-5">
      <button
        type="button"
        onClick={() => signOut()}
        style={{ backgroundColor: currentColor, color:"white", borderRadius: "10px" }}
        className="p-3 w-full hover:drop-shadow-xl hover:bg-grey"
      >
        Logout
      </button>
      </div>
    </div>

  );
};

export default UserProfile;
