import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomLoadingIndicator } from "../../../components";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DomainsConnectivity = ({ domainsArray }) => {
  const axiosPrivate = useAxiosPrivate();

  const [selectedDomain, setSelectedDomain] = useState("");
  const [loadingIndicatorActive, setLoadingIndicatorActive] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getDomainConnectivity = async (domain) => {
    setLoadingIndicatorActive(true);

    try {
      const response = await axiosPrivate.post(
        `/api/v1/warmup/reports/getDomainConnectivity`,
        { domainName: domain },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(response.data.data);
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  // useEffect(() => {
  //   getDomainConnectivity();
  // }, []);

  const handleSelectChange = (event) => {
    setSelectedDomain(event.target.value);
    getDomainConnectivity(event.target.value);
  };

  return (
    <Box>
      <Box mt={"30px"}>
        <Box mb={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel id="domains">Choose a domain</InputLabel>
            <Select
              labelId="domains"
              id="domain"
              value={selectedDomain}
              label="Choose a domain"
              onChange={handleSelectChange}
            >
              {domainsArray.map((domain) => {
                return (
                  <MenuItem key={domain.domainName} value={domain.domainName}>
                    {domain.domainName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {selectedDomain ? (
          <Box>{selectedDomain}</Box>
        ) : (
          <Box sx={{ textAlign: "center" }}>Please choose a domain</Box>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />
    </Box>
  );
};

export default DomainsConnectivity;
